<template lang="pug">
include ../../../configs/template
div.text-left.row
  div.col-sm-12.col-md-6.col-xl-4.py-3
    +data-info('tableHeaders.sailor_full_name_ukr', 'statement.sailor.full_name_ukr')
  div.col-sm-12.col-md-6.col-xl-4.py-3
    +data-info('tableHeaders.sailor_full_name_eng', 'statement.sailor.full_name_eng')
  div.col-sm-12.col-md-6.col-xl-4.py-3
    +data-info('dateBorn', 'statement.sailor.birth_date')
  div.col-sm-12.col-md-6.col-xl-4.py-3
    +data-info('createDate', 'statement.created_at')
  div.col-sm-12.col-md-6.col-xl-4.py-3
    +data-info('typeDoc', 'getDirectoryObject({ id:statement.type_document, value:"verificationDocumentTypes" })[nameLang]')
  div.col-sm-12.col-md-6.col-xl-4.py-3
    +data-info-status('status_document','getDirectoryObject({value: "statuses", id: statement.status_document})[nameLang]', 'getStatus(statement.status_document)')
  div.col-sm-12
    v-btn(color="primary" @click="goOnDocument") {{ $t('btn.goOnDocument') }}
  div.col-12
    v-divider
  div(v-if="statement.comments?.length").col-sm-12
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    v-divider
    div(v-for="(comment, index) in statement.comments" :key="comment.id").ml-4
      div
        +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
      v-divider(v-if="index !== statement.comments.length-1")
</template>

<script>
import { getStatus } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'
import { TYPE_DOCUMENTS_CONSTANTS, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: '',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getStatus
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  },
  methods: {
    goOnDocument () {
      let routeData

      let query = ![STATUSES_STATEMENT_CONSTANTS.clarificationOfData.rejected,
        STATUSES_STATEMENT_CONSTANTS.clarificationOfData.approved].includes(this.statement.status_document)
        ? { viewEditBlock: true } : {}

      let params = { id: this.statement.sailor.id, documentID: this.statement.document_id }

      switch (this.statement.type_document) {
        case TYPE_DOCUMENTS_CONSTANTS.SAILOR_IDENTITY_CARD:
          routeData = this.$router.resolve({ name: 'passports-sailors-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.CITIZEN_PASSPORT:
          routeData = this.$router.resolve({ name: 'passports-citizen-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.FOREIGN_PASSPORT:
          routeData = this.$router.resolve({ name: 'passports-international-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.EDUCATION:
          routeData = this.$router.resolve({ name: 'education-documents-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.QUALIFICATION:
          routeData = this.$router.resolve({ name: 'qualification-documents-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.PROOF_OF_DIPLOMA:
          routeData = this.$router.resolve({ name: 'qualification-documents-proof-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.CERTIFICATE_ETI:
          routeData = this.$router.resolve({ name: 'certification-certificates-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD:
          routeData = this.$router.resolve({ name: 'experience-records-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.PROTOCOL_SQC:
          routeData = this.$router.resolve({ name: 'sqc-protocols-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.MEDICAL_CERTIFICATE:
          routeData = this.$router.resolve({ name: 'medical-certificates-info', params, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD:
          routeData = this.$router.resolve({
            name: 'experience-records-line-info', params: { ...params, lineID: this.statement.document_id }, query })
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE:
          routeData = this.$router.resolve({ name: 'experience-reference-info', params, query })
          break
        default: routeData = this.$router.resolve({ name: this.$route.name, params: { ...this.$route.params } })
          break
      }
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
